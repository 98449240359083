import React, { useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Me from "../assets/zak.jpg";
import HTMLLogo from "../assets/htmllogo.svg";
import CSSLogo from "../assets/csslogo.svg";
import JSLogo from "../assets/jslogo.svg";
import REACTLogo from "../assets/reactlogo.svg";
import TWLogo from "../assets/tailwindlogo.svg";
import NEXTLogo from "../assets/nextlogo.svg";
import TSLogo from "../assets/typescriptlogo.svg";
import BSLogo from "../assets/bootstraplogo.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import NetflixSS from "../assets/netflix.png";
import { useState } from "react";
import TwitterImg from "../assets/twitter.png";
import LF from "../assets/learnfront.png";
import MenuIcon from "@mui/icons-material/Menu";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Aos from "aos";
import "aos/dist/aos.css";
import Resume from "../assets/ZakResumeFinal.pdf";
import HelloImg from "../assets/hello (2).svg";
import CloseIcon from "@mui/icons-material/Close";
import CarImg from '../assets/carhub.png'
import ArtSumImg from '../assets/articlesum.png'

const Main = () => {
  const [modal, setModal] = useState(false);

  function repoDeletedAlert(e) {
    e.preventDefault();

    alert("Repository privacy is private, due to live API keys");
  }

  function toggleModal() {
    setModal((prevModal) => !prevModal);
  }

  console.log(modal);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="container">
      {modal && (
        <div className="modal-container">
          <a onClick={toggleModal} href="#">
            <li className="modal-link">Home</li>
          </a>
          <a onClick={toggleModal} href="#about">
            <li className="modal-link">About</li>
          </a>
          <a onClick={toggleModal} href="#projects">
            <li className="modal-link">Projects</li>
          </a>
          <a onClick={toggleModal} target="_blank" href={Resume}>
            <li className="modal-link">Resume</li>
          </a>
        </div>
      )}
      <nav>
        <a href="">
          <h1 className="logo">Zak.jsx</h1>
        </a>
        <ul className="nav-links">
          <a href="#">
            <li className="nav-link">Home</li>
          </a>
          <a href="#about">
            <li className="nav-link">About</li>
          </a>
          <a href="#projects">
            <li className="nav-link">Projects</li>
          </a>
          <a target="_blank" href={Resume}>
            <li className="nav-link">Resume</li>
          </a>
          <a className="modal-toggle" href="#">
            <li className="modal-toggle" onClick={toggleModal}>
              {modal ? (
                <CloseIcon className="white menu-icon" />
              ) : (
                <MenuIcon className="menu-icon" />
              )}
            </li>
          </a>
        </ul>
      </nav>

      <main>
        <div className="main-container">
          <div className="main-container-left">
            <h1 className="main-title">
              Front-End Developer and Teacher{" "}
              <span className="hand-emoji">👋🏻</span>{" "}
            </h1>
            <p className="main-subtitle">
              Hi, I'm <strong>Zak.</strong> I'm a passionate web developer and
              CEO of
              <a href="https://www.learnfrontend.com.au" target="_blank">
                {" "}
                LearnFrontend,
              </a>{" "}
              an online platform where beginners learn how to code.
            </p>
            <p className="main-subtitle-secondary">
              Based in Melbourne, AU.
              <LocationOnIcon className="location-icon" />
            </p>
            <div className="cta-wrapper">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/zak-yahiat-6a9271243/"
              >
                <LinkedInIcon className="cta" />
              </a>
              <a href="https://github.com/zyahiat" target="_blank">
                <GitHubIcon className="cta" />
              </a>
            </div>
          </div>
          <div className="main-container-right">
            <img
              src={Me}
              className="main-img"
            />
          </div>
        </div>
        <div className="tech-stack-container">
          <h3 className="tech-stack-title">
            Tech Stack <span className="vertical-line">|</span>
          </h3>
          <div className="tech-stack">
            <img className="tech-logo" src={HTMLLogo} alt="" />
            <img className="tech-logo" src={CSSLogo} alt="" />
            <img className="tech-logo" src={JSLogo} alt="" />
            <img className="tech-logo" src={REACTLogo} alt="" />
            <img className="tech-logo" src={TWLogo} alt="" />
            <img className="tech-logo" src={BSLogo} alt="" />
            <img className="tech-logo" src={TSLogo} alt="" />
            <img className="tech-logo" src={NEXTLogo} alt="" />
          </div>
        </div>
        <section data-aos="fade-down" id="about" className="features">
          <div className="features-left">
            <img className="features-img" src={HelloImg} alt="" />
          </div>
          <div className="features-right">
            <p className="features-subtitle">ABOUT ME</p>
            <h4 className="features-title">
              A dedicated Front-End Developer based in Melbourne, Australia.
            </h4>
            <p className="features-para">
              As a frontend developer, I possess an extensive arsenal of skills
              in HTML, CSS, JavaScript, React, Tailwind, Bootstrap, TypeScript,
              NextJS and more... I also have a strong understanding and
              experience with <strong>backend development</strong> via Firebase.
              I excel in designing and maintaining responsive web applications
              that offer a smooth, user-friendly experience. My expertise lies
              in crafting dynamic, engaging interfaces through writing clean and
              optimized code, utilizing cutting-edge development tools and
              techniques. I am also a team player who thrives in collaborating
              with cross functional teams to produce outstanding applications.
            </p>
          </div>
        </section>
        <div id="projects" className="projects">
          <p data-aos="fade-down" className="features-subtitle">
            PROJECTS
          </p>
          <h4 data-aos="fade-down" className="features-title">
            Each project was built was based on real-world tasks.
          </h4>
          <div className="project">
            <img
              className="project-img"
              src={CarImg}
              alt=""
            />
            <div className="project-info">
              <h3 className="project-title">CarHub</h3>
              <h2 className="blue project-subtitle">
                NEXTJS 13 - Tailwind CSS - React - TypeScript
              </h2>
              <p className="project-desc">
                Built in NEXTJS 13, built a car searching application which
                allows the user to use search queries as well as filters to find
                the exact car they desire. Demonstrated client-side &
                server-side rendering, targetting multiple API's as well as
                NEXTJS Routing.
              </p>
              <div className="project-links-wrapper">
                <a target="_blank" href="https://github.com/zyahiat/cars_showcase">
                  <GitHubIcon />
                </a>
                <a target="_blank" href="https://cars-showcase-3m9r.vercel.app/">
                  <OpenInNewIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="project reverse">
            <img
              className="project-img"
              src={LF}
              alt=""
            />
            <div className="project-info">
              <h3 className="project-title">Learn Frontend</h3>
              <h2 className="gold project-subtitle">
                React - Firebase - Javascript - Stripe
              </h2>
              <p className="project-desc">
                An online tool designed to help aspiring web developers learn the basic fundamentals of HTML, CSS, JavaScript and React. Payment plans for students who want a more premium experience were integrated using Stripe mixed with Firebase's Firestore Backend.
              </p>
              <div className="project-links-wrapper">
                <a href="" onClick={repoDeletedAlert}>
                  <GitHubIcon />
                </a>
                <a target="_blank" href="https://www.learnfrontend.com.au">
                  <OpenInNewIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="project">
            <img
              className="project-img shrunk"
              src={ArtSumImg}
              alt=""
            />
            <div className="project-info">
              <h3 className="project-title">ArticleSum</h3>
              <h2 className="blue project-subtitle">
                React - Tailwind CSS - Javascript
              </h2>
              <p className="project-desc">
                Built with React and styled with Tailwind CSS, this application allows users to enter the url of an article or webpage, and summarise it via AI. Uses the OpenAI Summarizer API as well as the local storage API to track a users history.
              </p>
              <div className="project-links-wrapper">
                <a target="_blank" href="https://github.com/zyahiat/articlesum">
                  <GitHubIcon />
                </a>
                <a target="_blank" href="https://fluffy-raindrop-bc66b3.netlify.app/">
                  <OpenInNewIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" id="contact" className="btn-wrapper">
          <h1 className="contact-title">I would love to hear from you!</h1>
          <a href="mailto: zyahiat@gmail.com">
            <button className="contact-btn">Contact</button>
          </a>
        </div>
      </main>
      <footer>
        <h2 className="footer-header">Zak Yahiat</h2>
        <div className="footer-links">
          <a href="#">Home</a>
          <a href="#about">About</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact</a>
        </div>
        <p className="footer-copyright">Built by Zak Yahiat © 2023</p>
      </footer>
    </div>
  );
};

export default Main;
